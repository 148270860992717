<template>
  <div class="threeWide">
    <h1 v-if="title">{{ title }}</h1>
    <div class="contentWrapper">
      <slot></slot>
    </div>
    <!-- END content -->
  </div>
  <!-- END threeWide -->
</template>

<script>
export default {
  name: "ThreeWide",
  props: ["title"]
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.threeWide {
  padding: 25px 0 65px;
}
h1 {
  text-align: center;
  font-size: 45px;
  margin-bottom: 0px;
}
.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  @include for-medium-up {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
</style>
