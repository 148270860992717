<template>
  <div class="downloads contentWrapper">
    <h1>Downloads</h1>
    <img
      :src="imagesUrl + 'trackir-software-reticle@2x.png'"
      alt="Screenshot of TrackIR Software"
    />
    <h3>TrackIR Software</h3>
    <div class="changelogs">
      <changelog
        version="5.5.3"
        :link="softwareUrl + 'TrackIR_5.5.3.exe'"
      >
        <h3>Feature Enhancements</h3>
        <ul>
          <li>Added improved images for the panorama (pano) feature.</li>
          <li>Added a warning prompt when deleting the favorites list.</li>
          <li>Made the TrackIR URL clickable in the About TrackIR window. </li>
          <li>Added the “Space” category as a filter to the game titles list.</li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>Stopped the graphs view from being allowed to zoom out past the usable range.</li>
          <li>Fix for an issue where cancelling loading a pano image would cause the 3D sphere to turn white instead.</li>
          <li>Fix for default profile keeping a hotkey even though the software says that it won’t when switching profiles.</li>
        </ul>
      </changelog>
      <changelog
        version="5.5.2"
        :link="softwareUrl + 'TrackIR_5.5.2.exe'"
      >
        <h3>Feature Enhancements</h3>
        <ul>
          <li>Added the patch version number to the TrackIR title bar.</li>
          <li>Added a minimum application size.</li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>Fix for an issue when upgrading between 5.5.0 and 5.5.1 that caused not every file to update as expected.</li>
          <li>Fix for TIRMouse.exe not working.</li>
        </ul>
      </changelog>
      <changelog
        version="5.5.1"
        :link="softwareUrl + 'TrackIR_5.5.1.exe'"
      >
        <h3>Fixes</h3>
        <ul>
          <li>Fix for a crash when unplugging and re-plugging in the TrackIR 5 a few times.</li>
          <li>Fix for unsigned TrackIR SDK DLLs, which caused some games to not work.</li>
          <li>Fix for slow drifting when attempting to center the view.</li>
          <li>Fix for a crash when attempting to make profiles with the characters \\/:*?\"&gt;&lt;|/.</li>
          <li>Fix for not visibly disabling the IR Brightness setting when using the TrackClip Pro.</li>
        </ul>
      </changelog>
      <changelog
        version="5.5.0"
        :link="softwareUrl + 'TrackIR_5.5.0.exe'"
      >
        <h3>Feature Enhancements</h3>
        <ul>
          <li>Updated the installation process for the TrackIR software.</li>
          <li>Resolved some Windows 11 issues, by updating to VS2019 and corresponding redistributables.</li>
          <li>Updated the installer icon and shortcut icons to be different and not have "5" in the logo.</li>
          <li>Updated the TrackIR USB drivers.</li>
          <li>Added licenses for third party libraries that we use to the "Help" folder.</li>
          <li>Added patent information to the About Window.</li>
          <li>Internally modernized the application including updates to DirectX and other libraries.</li>
          <li>Removed the MSFS 2002 option from the installer.</li>
          <li>Hid the “Check for News” option due to it pointing to an outdated website.</li>
          <li>Updated language and old website URLs in the application.</li>
          <li>Added two new default profiles for flight and racing games.</li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>Fix for specific crash from loading the games list.</li>
          <li>Fixed an issue where ProcDump crashes were not being generated.</li>
          <li>Removed outdated documentation files from the “Help” folder due to new online documentation.</li>
          <li>Fixed issues with precision mode for the TrackIR5.</li>
        </ul>
      </changelog>
      <changelog
        version="5.4.2"
        :link="softwareUrl + 'TrackIR_5.4.2.exe'"
      >
        <h3>Feature Enhancements</h3>
        <ul>
          <li>
            Improved handling of situations where User Interface Privilege
            Isolation could interfere with TrackIR title integration, such as
            hotkeys failing to work when a TrackIR Enhanced title is run with
            elevated (administrator) privileges but the TrackIR software is not.
          </li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>
            Fixed a regression in TrackIR 5.4.1 where DirectInput joystick
            buttons 33-128 were not usable for hotkeys.
          </li>
        </ul>
        <h3>Known Issues</h3>
        <ul>
          <li>
            Since version 5.3.0, the TrackIR software requires Windows Vista or
            newer, and can no longer be used with Windows XP or below.
          </li>
        </ul>
      </changelog>
      <changelog
        version="5.4.1"
        :link="softwareUrl + 'TrackIR_5.4.1.exe'"
      >
        <h3>Fixes</h3>
        <ul>
          <li>
            Updated digital signatures in response to Microsoft / Internet
            Explorer deprecation of SHA1 as signature hash algorithm
            (KB3123479). This was causing the download to be flagged as having
            an "invalid" signature.
          </li>
        </ul>
        <h3>Known Issues</h3>
        <ul>
          <li>
            Since version 5.3.0, the TrackIR software requires Windows Vista or
            newer, and can no longer be used with Windows XP or below.
          </li>
        </ul>
      </changelog>
      <changelog
        version="5.4.0"
        :link="softwareUrl + 'TrackIR_5.4.0.exe'"
      >
        <h3>New Features</h3>
        <ul>
          <li>
            Added a toggle setting: "Automatically Minimize On TrackIR Enhanced
            Title Launch." In prior versions of the software, this behavior
            would always occur.
          </li>
        </ul>
        <h3>Feature Enhancements</h3>
        <ul>
          <li>
            Joystick/controller hotkeys now correctly differentiate between
            devices, rather than treating e.g. "Button0" on every device as
            identical.
          </li>
          <li>
            The 2D camera view is now horizontally mirrored (similar to a
            standard webcam) for a more intuitive user experience. If desired,
            it's possible to revert to the previous behavior using the viewport
            context menu; see the manual for details.
          </li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>
            Fixed an issue where certain settings (including template curves)
            might not be correctly migrated to per-user settings directory.
          </li>
          <li>
            Fixed an issue where certain NVIDIA GPUs might step down to a lower
            power state while the TrackIR software was active.
          </li>
          <li>
            Fixed an issue where the settings pane could appear truncated at
            large vertical resolutions.
          </li>
          <li>
            Fixed an issue where hotkeys bound to recenter would trigger a
            second time on release, rather than just when pressed.
          </li>
          <li>
            Fixed an issue where mouse hotkeys could exhibit noticeable latency.
          </li>
          <li>
            Fixed a crash when resizing the window after the hardware had been
            disconnected.
          </li>
          <li>Clarified the text of several error messages.</li>
          <li>Fixed ranges of speed/smoothing slider increment buttons.</li>
          <li>
            Increased default splitter width to remove unnecessary horizontal
            scrollbar from settings pane.
          </li>
        </ul>
        <h3>Known Issues</h3>
        <ul>
          <li>
            Since version 5.3.0, the TrackIR software requires Windows Vista or
            newer, and can no longer be used with Windows XP or below.
          </li>
        </ul>
      </changelog>
      <changelog
        version="5.3.0"
        :link="softwareUrl + 'TrackIR_5.3.0.exe'"
      >
        <h3>New Features</h3>
        <ul>
          <li>
            No longer requires the user to run the software with administrator
            privileges.
          </li>
          <li>
            Moved writable files out of Program Files directory and into
            <code>%AppData%\NaturalPoint\TrackIR 5\</code>
          </li>
        </ul>
        <h3>Feature Enhancements</h3>
        <ul>
          <li>
            Made install of Visual C++ redistributable and USB drivers
            prerequisites mandatory.
          </li>
        </ul>
        <h3>Fixes</h3>
        <ul>
          <li>Fixed crash when resizing 3D view area to zero height.</li>
          <li>
            User's Manual updated to reflect new system requirements and changes
            in behavior.
          </li>
        </ul>
        <h3>Known Issues</h3>
        <ul>
          <li>
            The TrackIR software now requires Windows Vista or newer, and can no
            longer be used with Windows XP or below.
          </li>
        </ul>
      </changelog>
    </div>

    <div class="additionalInfo">
      <ul class="alerts">
        <li class="heading">Alerts and Notices</li>
        <li>
          TrackIR 5 cameras with serial numbers 268500 or higher require
          software version 5.4.0 or newer.
        </li>
      </ul>
      <ul class="requirements">
        <li class="heading">System Requirements</li>
        <li>Requires TrackIR 5 Series hardware or newer</li>
        <li>Windows 10 or newer</li>
        <li>60 megabytes of free hard disk space</li>
      </ul>
    </div>
    <h2 class="belt">
      <span>Manuals</span>
    </h2>
    <three-wide>
      <product-card
        label="TrackIR 5 Manual 5.5.0"
        img="thumb-t5-manual@2x.png"
        :btnLink="docsUrl + 'TrackIR-software-5_5_0-Manual_24.07.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
      <product-card
        label="TrackIR 5 Guide"
        img="thumb-t5-qsg@2x.png"
        :btnLink="docsUrl + 'TrackIR5-Quick-Start-Guide_Outline_2024.07.02.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
      <product-card
        label="TrackIR 5 Guide (German)"
        img="thumb-t5-qsg-german@2x.png"
        :btnLink="docsUrl + 'qsg-trackir5-german-201106.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
    </three-wide>
    <three-wide>
      <product-card
        label="TrackClip Pro Guide"
        img="thumb-trackclip-pro-qsg@2x.png"
        :btnLink="docsUrl + 'TrackClipPro-Quick-Start-Guide_23.06.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
      <product-card
        label="TrackIR Manual 4.1.028"
        img="thumb-t4-manual@2x.png"
        :btnLink="docsUrl + 'TrackIR-4-Manual-24.02.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
      <product-card
        label="TrackIR 4 Guide"
        img="thumb-t4-qsg@2x.png"
        :btnLink="docsUrl + 'qsg-trackir4-24.02.pdf'"
        btnLabel="Download"
        :externalLink="true"
      ></product-card>
    </three-wide>
  </div>
</template>
<script>
import { IMAGES_URL, DOCS_URL, SOFTWARE_URL } from "@/constants/";
import Changelog from "@/components/Changelog";
import ThreeWide from "@/components/Layouts/ThreeWide";
import ProductCard from "@/components/ProductCard";

export default {
  name: "Downloads",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
    docsUrl: function () {
      return DOCS_URL;
    },
    softwareUrl: function () {
      return SOFTWARE_URL;
    },
  },
  methods: {
    toggleChanges: function (rowId) {
      document
        .querySelector("#row" + rowId + " .toggle-indicator")
        .classList.toggle("open");
      document
        .querySelector("#row" + rowId + " .changes")
        .classList.toggle("hide");
    },
  },
  components: {
    Changelog,
    ThreeWide,
    ProductCard,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.downloads {
  text-align: center;
  padding-bottom: 70px;
}
img {
  width: $tiny-width;
  height: auto;

  @include for-medium-up {
    width: 464px;
  }
}
h3 {
  font-weight: bold;
  margin: 20px 0 70px;
}
.changes {
  width: 100%;
  transition: all 0.3s ease;

  h3 {
    margin-bottom: 0px;
    font-weight: bold;
  }
  ul {
    margin-top: 0px;
  }
}
code {
  background: rgba(255, 255, 255, 0.3) none repeat scroll 0 0;
  border-radius: 5px;
  padding: 1px 9px;
}
.additionalInfo {
  margin-top: 70px;
  overflow: auto;
  ul {
    padding: 0 0 20px;
    margin: 0px;
    float: left;
    text-align: left;
    box-sizing: border-box;
    width: 100%;

    @include for-medium-up {
      width: 50%;
      padding: 0;
    }

    li {
      margin-left: 20px;
    }

    li.heading {
      list-style: none;
      padding-left: none;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
      color: #ffffff;
      margin-left: 0px;
    }
  }
  .alerts {
    padding-right: 30px;
  }
  .requirements {
    padding-left: 0px;
  }
}
.belt {
  margin-top: 70px;
  line-height: 0.5;
  text-align: center;

  span {
    display: inline-block;
    position: relative;
  }
}
.belt span:before,
.belt span:after {
  content: "";
  position: absolute;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  top: 50%;
  width: 100px;
  @include for-medium-up {
    width: 200px;
  }
}
.belt span:before {
  right: 100%;
  margin-right: 35px;
  @include for-medium-up {
    margin-right: 65px;
  }
}
.belt span:after {
  left: 100%;
  margin-left: 35px;
  @include for-medium-up {
    margin-left: 65px;
  }
}
</style>
