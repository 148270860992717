<template>
  <div class="productCard" :class="theme">
    <div v-if="img" class="productImg">
      <img :src="getImgUrl(img)" :alt="label" />
    </div>
    <div
      v-if="label"
      class="productLabel"
      v-bind:class="{ bold: boldLabel === true }"
    >
      {{ label }}
    </div>
    
    <div v-if="priceInfo" class="productPriceInfo">{{ priceInfo }}</div>
    <div class="childrenContent"><slot /></div>
    <div v-if="externalLink && btnLink" class="productBtn">
      <a target="blank" :href="btnLink">{{ btnLabel }} </a>
    </div>
    <div
      v-if="itemId && inStock"
      class="productBtn"
      v-bind:class="{ animatedBtn: addingItem }"
    >
      <a ref="productItemBtn" @click.prevent="buildCartItem()">{{ btnText }}</a>
    </div>
    <div
      v-else-if="coupon && inStock"
      class="productBtn"
      v-bind:class="{ animatedBtn: addingItem }"
    >
      <a ref="productCouponBtn" @click.prevent="buildCartCoupon()">{{
        btnText
      }}</a>
    </div>
    <div class="checkoutLink" v-if="itemAdded">
      <router-link to="/cart">View Cart</router-link>
    </div>
    <div class="out" v-if="!inStock && (itemId || promo )">{{ outOfStockMessage }}</div>
    <!--END itemAdded-->
  </div>
  <!-- END productCard -->
</template>

<script>
import { IMAGES_URL } from "@/constants/";
import { mapState } from "vuex";
import Vue from 'vue';
export default {
  name: "ProductCard",
  data: function () {
    return {
      addingItem: false,
      itemAdded: false,
      priceInfo: null,
      inStock: false,
      outOfStockMessage: 'Out of Stock'
    };
  },
  props: {
    label: { type: String },
    boldLabel: { type: Boolean, default: false },
    img: { type: String },
    thumbnail: { type: String },
    itemId: { type: Number },
    promo: { type: String },
    coupon: { type: String },
    btnLabel: { type: String, default: "Add to Cart" },
    btnLink: { type: String },
    externalLink: { type: Boolean, default: false },
    theme: { type: String, default: 'standard' },
  },
  computed: {
    ...mapState({
      currentCart: (state) => state.cart,
    }),
    btnText: function () {
      if (this.addingItem) {
        return "Adding To Cart";
      } else {
        return this.btnLabel;
      }
    }
  },
  mounted () {
    
    if(this.promo) {
      Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'cart/gaming/bundle/' + this.promo)
        .then((response) => {
          let itemsTotal = 0;
          let promoDiscount= response.data.promoDiscount;
          
          // loop through promo items
          response.data.promoItems.forEach((item) => {
            
            // Calculating total price
            itemsTotal += item.unitPrice;

          });

          //Apply the discount to the items total
          this.priceInfo = "$" + (itemsTotal - promoDiscount).toFixed(2);

          // Setting store status - items may be out of stock
          if(response.data.status == "active") {
            this.inStock = true;
          }

        });
    }
    if(this.itemId) {
      
      Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'product/store/' + this.itemId)
        .then((response) => {

          //Setting price
          this.priceInfo = "$" + response.data.price.toFixed(2);
          
          // Setting store status
          if(response.data.statusStore == "in-stock") {
            this.inStock = true;
          }else if(response.data.statusStore == "coming-soon") {
            this.outOfStockMessage = 'Coming Soon';
          }else if(response.data.statusStore == "discontinued") {
            this.outOfStockMessage = "Discontinued";
          }else if(response.data.statusStore == "preorder") {
            this.outOfStockMessage = "Preorder";
          }else if(response.data.statusStore == "call-to-order") {
            this.outOfStockMessage = "Call to Order";
          }else if(response.data.statusStore == "out-of-stock") {
            this.outOfStockMessage = "Out of Stock";
            this.inStock = false;
          }else{
            this.outOfStockMessage = "Error";
          }
        });
    }

  },
  methods: {
    getImgUrl(pic) {
      return IMAGES_URL + pic;
    },
    buildCartItem() {
      var cartItem = {
        cart_id: this.currentCart.id,
        product_id: this.itemId,
        thumbnail: this.thumbnail,
        quantity: 1,
        options: [],
      };
      this.$store
        .dispatch("addToCartAction", cartItem)
        .then(() => {
          this.addingItem = true;
        })
        .then(() => {
          setTimeout(() => {
            this.addingItem = false;
            this.itemAdded = true;
          }, 2000);
        });
    },
    buildCartCoupon() {
      this.$store
        .dispatch("addBundleToCartAction", { hash: this.coupon, quanity: 1 })
        .then(() => {
          this.addingItem = true;
        })
        .then(() => {
          setTimeout(() => {
            this.addingItem = false;
            this.itemAdded = true;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.productCard {
  text-align: center;
  padding: 10px;
  align-self: end;
  position: relative;


  &.box {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto 1fr auto auto;
    padding: 30px 30px 40px 30px;

    .productImg {
      padding-bottom: 20px;
    }

    .productLabel {
      text-align: left;
      margin-bottom: 20px;
      font-family: $font-stack-heading;
      color: #ffffff;
      font-size: 32px;
    }

    .productPriceInfo {
      text-align: left;
      margin:0 0 20px;
      font-family: $font-stack-heading;
    }
    .out {
      align-self: flex-end;
    }
    
    p {
      padding: 0;
      margin: 0;
    }
    .productBtn {
      width: 216px;
      margin:0 auto;
    }
  }


  .productImg {
    padding: 20px 0 0;
    max-height: 175px;
    box-sizing: border-box;
    img {
      max-width: 100%;
      max-height: 145px;
      vertical-align: bottom;
    }
  }
  .childrenContent {
    text-align: left;
  }
  .productLabel {
    margin: 10px 0px;

    &.bold {
      font-weight: 700;
      color: #ffffff;
    }
  }
  .productPriceInfo {
    margin: 10px 0px;
  }

  .checkoutLink a {
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    padding-top: 10px;
    text-decoration: underline;
    animation-duration: 0.5s;
    animation-name: slideDown;

    &:hover {
      color: #ffffff;
    }
  }
}
.productBtn {
  white-space: nowrap;
}

.out{
  padding:12px 54px;
  margin:0 2px;
}

.productBtn a {
  @include btn();
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.productBtn.animatedBtn a {
  color: #ffffff;
  background-color: transparent;
  text-shadow: none;
}
.productBtn.animatedBtn a:before {
  bottom: 0%;
  top: auto;
  height: 100%;
}
.productBtn a:before {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 0px;
  width: 100%;
  z-index: -1;
  content: "";
  background: #000000;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}




@keyframes slideDown {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
