<template>
  <div id="row1" class="changelog">
    <div class="details">
      <a
        href
        @click.prevent="changesDisplayed = !changesDisplayed"
        :class="{ open: changesDisplayed }"
        class="toggle-indicator icon-down-open-big"
      ></a>
      <h3 class="version">{{ version }}</h3>
      <p v-if="posted" class="postedDate">Posted {{ posted }}</p>
    </div>
    <div class="btnWrapper">
      <a
        :id="version"
        :href="link"
        class="btn download-link cta click-track-software-download"
        >Download</a
      >
    </div>
    <transition-slide>
      <div v-if="changesDisplayed" class="changes">
        <slot />
      </div>
    </transition-slide>
  </div>
</template>

<script>
import TransitionSlide from "@/components/Transitions/TransitionSlide";
export default {
  name: "Changelog",
  props: ["version", "posted", "link"],
  data() {
    return {
      changesDisplayed: false
    };
  },
  components: {
    TransitionSlide
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.changelog {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}
.toggle-indicator {
  width: 24px;
  float: left;
  transform: rotate(-90deg);
  transition: all 0.3s ease;

  &.open {
    transform: rotate(0deg);
  }
}
.details {
  width: 50%;
  h3 {
    margin: 0px;
    font-weight: bold;
  }
  p {
    margin: 5px 0 15px;
  }
}
.btnWrapper {
  width: 50%;
  text-align: right;
  margin-bottom:23px;

  .btn {
    display: inline-block;
    padding: 5px 20px;
  }

  @include for-medium-up {
    .btn {
      padding: 10px 82px;
    }
  }
}
</style>
